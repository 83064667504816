<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: manualFeeding.vue
Description:This file gives the form where the manual feed  details was filled by user
-->
<template>
  <layout-pondlogs
    headerType="card"
    containerType="card"
    class="culture-manual-feeding"
  >
    <p slot="custom-title" class="layout-title">
      {{ $t("Comn_manual_feeding") }}
    </p>
    <template slot="header" v-if="getArrResources.length > 0">
      <div class="switch-container">
        <div class="switch-label">
          {{ $t("Comn_date_range") }}
        </div>
        <!-- :active-text="'test'" -->
        <el-switch v-model="dateRangeSwitch" active-color="#0A2463" @change="handleSwitchChange">
        </el-switch>
      </div>
      <er-single-day-picker
        v-if="!dateRangeSwitch"
        :value="selectedDate[0]"
        :format="this.upm__getFormatDateString"
        value-format="yyyy-MM-dd"
        class="align-item-left"
        @change-prev-day="prevDayClick"
        @change-next-day="nextDayClick"
        @change-day="handleChangeInDateRange"
      ></er-single-day-picker>
      <er-date-picker
        v-else
        size="mini"
        :timeZoneString="getUserTimeZoneString"
        popper-class="card-chart__date-picker"
        :value="selectedDate"
        :format="this.upm__getFormatDateString"
        :value-format="datePickerFormat"
        :arrShortcuts="arrDatePickerShortCuts"
        @input="handleChangeInDateRange"
      />
      <er-input
        inputType="search"
        size="mini"
        v-model="searchPond"
        :placeholder="$t('Ponds_select_pond_name')"
        suffix-icon="el-icon-search"
        @change="handelSearchChange"
      ></er-input>
    </template>
    <el-row
      class="row-2"
      slot="layout-pondlogs-scrollable-main"
      element-loading-background="white"
      type="flex"
      justify="center"
    >
      <template v-if="loading">
        <loader></loader>
      </template>
      <template v-else-if="getArrResources.length > 0">
        <el-col :span="24">
          <el-alert
            v-if="ehm__unhandledErrorMessage"
            :title="ehm__unhandledErrorMessage"
            @close="ehm__handleAlertClose"
            type="warning"
          ></el-alert>
          <el-row>
            <er-data-tables
              :tableData="tableData"
              :columns="tableColumns"
              unique-id="manual-feeding"
              :merge="{ startColumnNumber: 1, endColumnNumber: 1 }"
              :action-column="false"
              :el-table-props="table_props"
              ref="manualFeedDataTable"
              type="white-header-table"
              :columnsSelected="['date']"
              :pagination-slot="true"
              :searchOnTableData="searchPond"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <template v-slot:pond_name_sort_key="{ row }">
                <er-highlight-search-component
                  :highlightStrings="row.data.highlightStrings"
                  :individualRowData="row.data"
                  keyName="pond_title"
                  :withoutHighlightStrings="row.data.pond_title"
                />
              </template>
              <template v-slot:date="{ row }">
                <el-date-picker
                  size="mini"
                  :clearable="false"
                  v-model="row.data.date"
                  type="datetime"
                  format="dd-MMM-yy HH:mm"
                  :value-format="dateFormat"
                  class="date-picker"
                  :picker-options="pickerOptions"
                />
              </template>
              <template v-slot:feed_id="{ row }">
                <er-select
                  size="mini"
                  :value="row.data.feed_id"
                  :disabled="getArrResources.length === 0"
                  :placeholder="$t('select_feed')"
                  class="feed-id"
                  @change="handleChangeInResources($event, row.data)"
                >
                  <el-option
                    v-for="resource in getArrResources"
                    :disabled="!getIsOptionAvailable(row.data, resource._id)"
                    :value="resource._id"
                    :label="
                      resource.feed_type
                        ? `${resource.name} - ${resource.feed_type}`
                        : resource.name
                    "
                    :key="resource._id"
                  ></el-option>
                </er-select>
              </template>

              <template v-slot:feed_dispensed="{ row }">
                <el-row>
                  <el-input-number
                    :controls="false"
                    size="mini"
                    v-model="row.data.feed_dispensed"
                    :min="0"
                    :placeholder="$t('Comn_feed_dispensed_kg')"
                    @change="
                      handleFeedDispensedChange($event, row.data, row.$index)
                    "
                  ></el-input-number>
                </el-row>
              </template>
              <template v-slot:action_header>
                <el-row class="action-column-cell-row">{{
                  tableColumns.action.label
                }}</el-row>
              </template>
              <template v-slot:action="{ row }">
                <el-row class="action-column-cell-row">
                  <er-button
                    v-if="showAddButton(row.data)"
                    btnType="table_add"
                    class="btn-table-action"
                    :disabled="disableAddButton(row.data)"
                    @click="
                      handleAddManualFeedRecord($event, row.data, row.$index)
                    "
                    :showLabel="true"
                    :showIcon="true"
                    type="text"
                  ></er-button>
                  <er-button
                    v-else
                    class="btn-table-action"
                    btnType="delete"
                    :disabled="isReadOnly"
                    @click="
                      handleDeleteManualFeedRecord($event, row.data, row.$index)
                    "
                    :showLabel="true"
                    :showIcon="true"
                    type="text"
                  ></er-button>
                </el-row>
              </template>
              <template slot="pagination" style="padding: 10px">
                <er-button
                  :disabled="isReadOnly"
                  @click="submitFeedRecords"
                  btnType="save"
                  size="mini"
                  :showIcon="true"
                  :showLabel="true"
                  :loading="loading"
                ></er-button>
                <er-button
                  btnType="cancel"
                  size="mini"
                  :disabled="isReadOnly"
                  @click="resetData"
                  :showLabel="true"
                ></er-button>
              </template>
            </er-data-tables>
          </el-row>
        </el-col>
      </template>
      <template v-else>
        <el-row>
          <el-tag type="error small-text" style="margin-bottom: 10em">
            <i18n path="Comn_no_items_type_avail_msg" tag="span">
              <template v-slot:item>
                <strong>{{ $t("Comn_resource") }}</strong>
              </template>
              <template v-slot:type>
                <strong>{{ $t("Comn_feed") }}</strong>
              </template>
              <template v-slot:action>
                <strong>
                  <a href="#" v-on:click.prevent="addResClickHandler($event)">{{
                    $t("Add_Resource")
                  }}</a>
                </strong>
              </template>
            </i18n>
          </el-tag>
        </el-row>
      </template>
    </el-row>
  </layout-pondlogs>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/base/Loader";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
export default {
  props: ["tabData"],
  mixins: [
    errorHandlerMixin,
    filersMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    redirectsMixin
  ],
  components: { Loader },
  data: function() {
    return {
      date: [],
      checkList: [],
      searchPond: "",
      loading: false,
      tableFeedData: [],
      table_props: {
        size: "small",
        defaultSort: { prop: "pond_title", order: "ascending" },
        height: "calc(100vh - var(--height-gutter))"
      },
      multiSelectedFeedArr: [],
      visible: false,
      newFeedRecord: {
        pond_id: "",
        date: "",
        feed_dispensed: 0,
        feed_id: "",
        modified: false
      },
      selectedHeaders: {},
      showErrorAlerts: false,
      dateFormat: "yyyy-MM-dd HH:mm:ss",
      pondIdToLastRecordId: {},
      pondIdToResourcesUsed: {},
      resourceToPondId: {},
      datePickerFormat: "yyyy-MM-dd",
      dateRangeSwitch: false
    };
  },
  watch: {
    searchPond: function(newValue, oldValue) {
      this.tableFeedData =
        this.searchPond !== ""
          ? this.$lodash
              .cloneDeep(this.emptyData)
              .filter((x) =>
                x.pond_title
                  .toLowerCase()
                  .includes(this.searchPond.toLowerCase())
              )
          : this.emptyData;
    },
    dateRangeSwitch: function(newValue, oldValue) {
      console.log("dateRangeSwitch", newValue, oldValue);
      this.date = [
        this.dhm__formatTZ(this.dhm__getTodayInUserTZ, "yyyy-MM-dd"),
        this.dhm__formatTZ(this.dhm__getTodayInUserTZ, "yyyy-MM-dd")
      ];
    }
  },
  computed: {
    ...mapGetters({
      getManualFeedRecords: "pond/getManualFeedRecords"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    location() {
      return this.$store.getters["user/getCurrUserLocation"];
    },
    locationId() {
      return this.location._id;
    },
    PondsMapType() {
      return this.$store.getters["pond/getMapPondidPond"];
    },
    disableAddButton() {
      return (mFeedRecord) => {
        return [
          this.isReadOnly,
          this.getArrResources.length ===
            this.pondIdToResourcesUsed[mFeedRecord.pond_id].size
        ].some((x) => x === true);
      };
    },
    arrDatePickerShortCuts: function() {
      return [
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          0,
          "days",
          this.$t("Comn_today"),
          false,
          "TODAY"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(2, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(7, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(15, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(30, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(2, "months")
      ];
    },
    disableDateMethod: function(time) {
      // const soc = this.getMinStartCultureDate;
      // const startDate = this.dhm__dateUtilsLib.startOfDay(
      //   this.dhm__dateUtilsLib.utcToZonedTime(
      //     this.dhm__dateUtilsLib.parseISO(soc),
      //     this.getUserTimeZoneString
      //   )
      // );
      // const endDate = this.dhm__dateUtilsLib.endOfDay(
      //   this.dhm__getTodayInUserTZ
      // );
      // const currDate = time;
      // const paramObj = {
      //   dateRange: [startDate, endDate],
      //   date: currDate,
      //   timeZone: this.getUserTimeZoneString,
      //   actionsOnDate: [],
      //   actionsOnDateRangeItem: []
      // };
      // return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
      return false;
    },
    getPondsMapBasedOnDateSelection() {
      const selectedDate = this.getDateObjForSelectedDate;
      return Array.from(this.PondsMapType).reduce((acc, [key, value]) => {
        const stockingDate = this.dhm__castUserUTCToUTCDateObj(
          value.cultivation_date
        );

        // (from date <= stocking date && to_date >= stockig_date) || (from date >= stocking date))
        // to date >= stocking date
        // 1, 3 7
        // 4 3 7
        // 1 3  2
        //   3
        if (
          !this.dateRangeSwitch &&
          (this.dhm__dateUtilsLib.isEqual(stockingDate, selectedDate[0]) ||
            this.dhm__dateUtilsLib.isBefore(stockingDate, selectedDate[0]))
        ) {
          acc.push(value);
        }
        if (
          this.dateRangeSwitch &&
          (((this.dhm__dateUtilsLib.isEqual(selectedDate[0], stockingDate) ||
            this.dhm__dateUtilsLib.isBefore(selectedDate[0], stockingDate)) &&
            (this.dhm__dateUtilsLib.isEqual(selectedDate[1], stockingDate) ||
              this.dhm__dateUtilsLib.isAfter(selectedDate[1], stockingDate))) ||
            this.dhm__dateUtilsLib.isEqual(selectedDate[0], stockingDate) ||
            this.dhm__dateUtilsLib.isAfter(selectedDate[0], stockingDate))
        ) {
          acc.push(value);
        }
        return acc;
      }, []);
    },
    getArrResources() {
      return this.$store.getters["resource/getArrResources"];
    },
    getArrResourceIds() {
      return this.getArrResources.map((x) => x._id);
    },
    getObjResources() {
      return this.$store.getters["resource/getObjResources"];
    },
    showAddButton() {
      return (data) =>
        this.pondIdToLastRecordId[data.pond_id] === data.pond_name_sort_key;
    },
    getExistingRecords() {
      const manualFeedRecords = this.getManualFeedRecords || [];
      return manualFeedRecords.reduce((acc, { pond_id, date, feeds }) => {
        acc[pond_id] = feeds.map(({ feed_dispensed, feed_id }, index) => {
          const newFeedRecord = this.$lodash.cloneDeep(this.newFeedRecord);
          newFeedRecord.pond_id = pond_id;
          newFeedRecord.pond_title = (
            this.getPondWithId(pond_id) || { title: "No Title" }
          ).title;
          newFeedRecord.date = this.dhm__dateUtilsLib.formatTZ(
            this.dhm__dateUtilsLib.utcToZonedTime(
              this.dhm__castUserUTCToUTCDateObj(date),
              this.getUserTimeZoneString
            ),
            this.dhm__dateUtilsLib.isoFormatString
          );
          newFeedRecord.modified = false;
          newFeedRecord.pond_feed_index = index + 1;
          newFeedRecord.pond_name_sort_key = `${newFeedRecord.pond_title}_${newFeedRecord.pond_feed_index}`;
          newFeedRecord.feed_id = feed_id._id || feed_id;
          newFeedRecord.feed_dispensed = feed_dispensed;
          return newFeedRecord;
        });
        return acc;
      }, {});
    },
    selectedDate: {
      get() {
        return this.date;
      },
      set(value) {
        this.date = value;
      }
    },
    getDateObjForSelectedDate: function() {
      if (this.dateRangeSwitch) {
        return [
          this.dhm__dateUtilsLib.toDate(
            this.dhm__dateUtilsLib.utcToZonedTime(
              this.dhm__castUserUTCToUTCISO(new Date(this.selectedDate[0])),
              this.getUserTimeZoneString
            )
          ),
          this.dhm__dateUtilsLib.toDate(
            this.dhm__dateUtilsLib.utcToZonedTime(
              this.dhm__castUserUTCToUTCISO(new Date(this.selectedDate[1])),
              this.getUserTimeZoneString
            )
          )
        ];
      } else {
        return [
          this.dhm__dateUtilsLib.toDate(
            this.dhm__dateUtilsLib.utcToZonedTime(
              this.dhm__castUserUTCToUTCISO(new Date(this.selectedDate[0])),
              this.getUserTimeZoneString
            )
          )
        ];
      }
    },
    getIsToday() {
      if (this.dateRangeSwitch) {
        return [
          this.dhm__dateUtilsLib.isEqual(
            this.getDateObjForSelectedDate[0],
            this.dhm__getTodayInUserTZ
          ),
          this.dhm__dateUtilsLib.isEqual(
            this.getDateObjForSelectedDate[1],
            this.dhm__getTodayInUserTZ
          )
        ];
      } else {
        return [
          this.dhm__dateUtilsLib.isEqual(
            this.getDateObjForSelectedDate[0],
            this.dhm__getTodayInUserTZ
          )
        ];
      }
    },
    getIsOptionAvailable() {
      return (mFeedRecord, feed_id) => {
        const pondId = mFeedRecord.pond_id;
        const mFeedRecordFeedId = mFeedRecord.feed_id;
        return (
          mFeedRecordFeedId === feed_id ||
          !this.pondIdToResourcesUsed[pondId].has(feed_id) ||
          !this.resourceToPondId[feed_id].has(pondId)
        );
      };
    },
    tableColumns() {
      return {
        pond_name_sort_key: {
          label: this.$t("Comn_pond_name"),
          prop: "pond_title",
          default: true,
          sortable: false,
          minWidth: 100,
          enableSearch: true,
          searchProperty: "pond_title"
        },
        feed_id: {
          label: this.$t("Comn_feed_name"),
          prop: "feed_id",
          default: true,
          minWidth: 270
        },
        feed_dispensed: {
          label: this.$t("Day_feed_kg"),
          prop: "feed_dispensed",
          default: true,
          minWidth: 270
        },
        action: {
          label: this.$t("Comn_actions"),
          default: true,
          minWidth: 100
        }
      };
    },
    emptyData() {
      return this.getPondsMapBasedOnDateSelection.map((value) => {
        const key = value._id;
        const newFeedRecord = this.$lodash.cloneDeep(this.newFeedRecord);
        newFeedRecord.pond_id = key;
        newFeedRecord.pond_title = (
          this.getPondWithId(key) || { title: "No Title" }
        ).title;
        newFeedRecord.pond_feed_index = 1;
        newFeedRecord.pond_name_sort_key = `${newFeedRecord.pond_title}_${newFeedRecord.pond_feed_index}`;
        newFeedRecord.modified = false;
        newFeedRecord.feed_id = this.getArrResources[0]._id;
        newFeedRecord.date = [
          this.dhm__dateUtilsLib.formatTZ(
            this.getDateObjForSelectedDate[0],
            this.dhm__dateUtilsLib.isoFormatString
          ),
          this.dhm__dateUtilsLib.formatTZ(
            this.getDateObjForSelectedDate[1],
            this.dhm__dateUtilsLib.isoFormatString
          )
        ];
        return newFeedRecord;
      });
    },
    tableData: function() {
      return this.tableFeedData;
    },
    getPref: function() {
      return localStorage.getItem("table-pref-manual-feed");
    },
    pickerOptions: function() {
      return {
        disabledDate: this.handleDisabledDate
      };
    },
    sortMethodForColumns: function() {
      return {
        pond_name_sort_key: this.$commonUtils.alphaNumericComparator
      };
    }
  },
  mounted() {
    this.initDate();
    this.initComponent();
  },
  methods: {
    ...mapActions({
      fetchAllPonds: "pond/fetchAllPonds",
      fetchAllResources: "resource/fetchAllResources",
      fetchAllManualFeedingRecords: "pond/fetchAllManualFeedingRecords"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleSwitchChange() {
      this.mixPanelEventGenerator({ eventName: "Feeding -Manual Feeding - Date Range Toggle" });
    },
    handelSearchChange() {
      if (this.searchPond !== '') {
        this.mixPanelEventGenerator({ eventName: "Feeding -Manual Feeding - Search" });
      }
    },
    handleFeedDispensedChange(updtPropVal, row, ind) {
      if (isNaN(+updtPropVal) || +updtPropVal < 0) {
        row.feed_dispensed = updtPropVal;
      } else {
        row.feed_dispensed = +(+updtPropVal).toFixed(2);
      }
      if (this.searchPond !== "") {
        this.tableFeedData[ind].feed_dispensed = +updtPropVal.toFixed(2);
      }
    },
    addResClickHandler(event) {
      event.preventDefault();
      try {
        this.$gblUAMCanUserEdit(this.tabData);

        this.rdm__navigateToPathWithLocationId({ path: "/user/resources" });
        // window.location.href = "/user/resources";
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    resetData: async function() {
      await this.initComponent();
    },
    handleDisabledDate: function(time) {
      return this.dhm__dateUtilsLib.isAfter(
        time,
        this.dhm__dateUtilsLib.endOfDay(this.dhm__getTodayInUserTZ)
      );
    },
    getPondWithId: function(pond_id) {
      return this.PondsMapType.get(pond_id);
    },
    handleChangeInDateRange: async function(date) {
      console.log("date", date);
      if (this.dateRangeSwitch) {
        this.selectedDate = date;
      } else {
        this.selectedDate[0] = date;
        console.log("date", date);
      }
      this.mixPanelEventGenerator({ eventName: "Feeding -Manual Feeding - Date Filter" });
      try {
        await this.initComponent();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    initDate: function() {
      this.date = [
        this.dhm__formatTZ(this.dhm__getTodayInUserTZ, "yyyy-MM-dd"),
        this.dhm__formatTZ(this.dhm__getTodayInUserTZ, "yyyy-MM-dd")
      ];
    },
    prevDayClick: async function(date) {
      console.log("date", date);
      this.selectedDate[0] = date;
      this.date.push(date);
      this.mixPanelEventGenerator({ eventName: "Feeding -Manual Feeding - Date Filter" });
      await this.initComponent();
    },
    nextDayClick: async function(date) {
      console.log("date", date);
      this.selectedDate[0] = date;
      this.date.push(date);
      this.mixPanelEventGenerator({ eventName: "Feeding -Manual Feeding - Date Filter" });
      await this.initComponent();
    },
    initComponent: async function() {
      this.loading = true;
      try {
        await Promise.all([
          // this.fetchAllManualFeedingRecords({
          //   location_id: this.location._id,
          //   get_all: true,
          //   from_date: `${this.date[0]}T00:00:00.000Z`,
          //   to_date: `${this.date[1]}T23:59:59.999Z`
          // }),
          this.fetchAllPonds({
            location_id: this.location._id,
            get_all: true,
            status: "ACTIVE",
            include: ["title", "status", "cultivation_date"]
          }),
          this.fetchAllResources({
            type: "FEED",
            order_by: "name",
            order_type: "asc",
            // include_deleted: true,
            get_all: true
          })
        ]);
        this.prepMapOfPondIdToFeedCount();
        if (this.getArrResources.length === 0) return;
        this.prepMapOfFeedIdToSetPondIds();
        this.prepTableData();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.manualFeedDataTable) {
            this.$refs.manualFeedDataTable.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    prepMapOfPondIdToFeedCount: function() {
      this.pondIdToLastRecordId = this.getPondsMapBasedOnDateSelection.reduce(
        (acc, value) => {
          acc[value._id] = undefined;
          return acc;
        },
        {}
      );
    },
    prepMapOfFeedIdToSetPondIds: function() {
      this.resourceToPondId = {};
      this.getArrResourceIds.reduce((acc, currId) => {
        acc[currId] = new Set();
        return acc;
      }, this.resourceToPondId);
    },
    handleChangeInResources: function(newFeedId, mFeedRecord) {
      const pondId = mFeedRecord.pond_id;
      const prevFeedId = mFeedRecord.feed_id;
      if (prevFeedId !== newFeedId) {
        this.mixPanelEventGenerator({ eventName: "Feeding -Manual Feeding - Feed Name" });
      }
      this.pondIdToResourcesUsed[pondId].delete(prevFeedId);
      this.resourceToPondId[prevFeedId].delete(pondId);
      this.resourceToPondId[newFeedId].add(pondId);
      this.pondIdToResourcesUsed[pondId].add(newFeedId);
      mFeedRecord.feed_id = newFeedId;
    },
    prepTableData: function() {
      // const existingRecords = this.getExistingRecords || {};
      console.log(
        "this.getPondsMapBasedOnDateSelection",
        this.getPondsMapBasedOnDateSelection
      );
      this.tableFeedData =
        this.searchPond !== ""
          ? this.tableFeedData
          : this.getPondsMapBasedOnDateSelection.map((value) => {
              const key = value._id;
              // if ((existingRecords[key] || []).length) {
              //   return existingRecords[key];
              // }
              const newFeedRecord = this.$lodash.cloneDeep(this.newFeedRecord);
              newFeedRecord.pond_id = key;
              newFeedRecord.pond_title = (
                this.getPondWithId(key) || { title: "No Title" }
              ).title;
              newFeedRecord.pond_feed_index = 1;
              newFeedRecord.pond_name_sort_key = `${newFeedRecord.pond_title}_${newFeedRecord.pond_feed_index}`;
              newFeedRecord.modified = false;
              newFeedRecord.feed_id = this.getArrResources[0]._id;
              // const dateObj = this.getIsToday
              //   ? this.dhm__getTodayInUserTZ
              //   : this.getDateObjForSelectedDate;
              if (this.dateRangeSwitch) {
                // newFeedRecord.date = [
                //   this.dhm__dateUtilsLib.formatTZ(
                //     this.dhm__getTodayInUserTZ,
                //     this.dhm__dateUtilsLib.isoFormatString
                //   ),
                //   this.dhm__dateUtilsLib.formatTZ(
                //     this.dhm__getTodayInUserTZ,
                //     this.dhm__dateUtilsLib.isoFormatString
                //   )
                // ];
                // newFeedRecord.date = [
                //   this.dhm__dateUtilsLib.formatTZ(
                //     this.getDateObjForSelectedDate[0],
                //     this.dhm__dateUtilsLib.isoFormatString
                //   ),
                //   this.dhm__dateUtilsLib.formatTZ(
                //     this.getDateObjForSelectedDate[1],
                //     this.dhm__dateUtilsLib.isoFormatString
                //   )
                // ];
                newFeedRecord.from_date = this.dhm__dateUtilsLib.formatTZ(
                  this.getDateObjForSelectedDate[0],
                  this.dhm__dateUtilsLib.isoFormatString
                );
                newFeedRecord.to_date = this.dhm__dateUtilsLib.formatTZ(
                  this.getDateObjForSelectedDate[1],
                  this.dhm__dateUtilsLib.isoFormatString
                );
              } else {
                // newFeedRecord.date = [
                //   this.dhm__dateUtilsLib.formatTZ(
                //     this.getDateObjForSelectedDate[0],
                //     this.dhm__dateUtilsLib.isoFormatString
                //   )
                // ];
                newFeedRecord.from_date = this.dhm__dateUtilsLib.formatTZ(
                  this.getDateObjForSelectedDate[0],
                  this.dhm__dateUtilsLib.isoFormatString
                );
                newFeedRecord.to_date = this.dhm__dateUtilsLib.formatTZ(
                  this.getDateObjForSelectedDate[0],
                  this.dhm__dateUtilsLib.isoFormatString
                );
              }
              return newFeedRecord;
            });
      this.tableFeedData = this.tableFeedData.flat(1);
      this.tableFeedData = this.tableFeedData.sort((a, b) => {
        const value = this.$commonUtils.alphaNumericComparator(
          a.pond_name_sort_key,
          b.pond_name_sort_key
        );
        return value;
      });
      this.tableFeedData.reduce((acc, curr, index) => {
        acc[curr.pond_id] = curr.pond_name_sort_key;
        return acc;
      }, this.pondIdToLastRecordId);
      this.pondIdToResourcesUsed = this.tableFeedData.reduce(
        (acc, curr, index) => {
          if (acc[curr.pond_id] === undefined) {
            acc[curr.pond_id] = new Set();
          }
          acc[curr.pond_id].add(curr.feed_id);
          return acc;
        },
        {}
      );
      this.tableFeedData.reduce((acc, curr, index) => {
        acc[curr.feed_id].add(curr.pond_id);
        return acc;
      }, this.resourceToPondId);
    },
    handleChangeInColumnSelection: function(selectedColumns) {
      const columnObj = selectedColumns.reduce((acc, curr) => {
        acc[curr] = this.tableColumns[curr];
        return acc;
      }, {});
      this.selectedHeaders = Object.assign({}, columnObj);
    },
    handleAddManualFeedRecord: async function($event, mFeedRecord, rowIndex) {
      const pondId = mFeedRecord.pond_id;
      const availableResourcesIds = this.getArrResourceIds;
      const usedResourcesIds = this.pondIdToResourcesUsed[pondId];
      const unUsedResourceIds = availableResourcesIds.filter(
        (id) => !usedResourcesIds.has(id)
      );
      mFeedRecord.modified = true;
      const newFeedRecord = this.$lodash.cloneDeep(this.newFeedRecord);
      newFeedRecord.pond_id = mFeedRecord.pond_id;
      newFeedRecord.pond_title = mFeedRecord.pond_title;
      newFeedRecord.feed_id = unUsedResourceIds[0];
      newFeedRecord.pond_feed_index = mFeedRecord.pond_feed_index + 1;
      newFeedRecord.pond_name_sort_key = `${newFeedRecord.pond_title}_${newFeedRecord.pond_feed_index}`;
      // newFeedRecord.date = mFeedRecord.date;
      newFeedRecord.from_date = mFeedRecord.from_date;
      newFeedRecord.to_date = mFeedRecord.to_date;
      newFeedRecord.feed_dispensed = 0;
      this.tableFeedData.splice(
        rowIndex === 0 ? 0 : rowIndex,
        0,
        newFeedRecord
      );
      this.pondIdToResourcesUsed[pondId].add(newFeedRecord.feed_id);
      this.resourceToPondId[newFeedRecord.feed_id].add(pondId);
    },
    handleDeleteManualFeedRecord: async function(
      $event,
      mFeedRecord,
      rowIndex
    ) {
      if (mFeedRecord.pond_feed_index === 0) return;
      const feedRecordPondId = mFeedRecord.pond_id;
      const feedRecordFeedId = mFeedRecord.feed_id;
      this.tableFeedData.splice(rowIndex, 1);
      this.pondIdToResourcesUsed[feedRecordPondId].delete(mFeedRecord.feed_id);
      this.resourceToPondId[feedRecordFeedId].delete(mFeedRecord.pond_id);
    },
    getValidationError(feed_dispensed, pond_title) {
      if (!(feed_dispensed <= 10000000)) {
        return {
          message: `${this.$t("Day_feed_kg")} ${this.$t(
            "Comn_value_should_be"
          )} \u2264 10000000 in ${pond_title}`
        };
      }
    },
    validatePayload(labRec, pond_title) {
      const errors = [];
      if (this.getValidationError(labRec, pond_title)) {
        errors.push(this.getValidationError(labRec, pond_title));
      }
      return errors;
    },
    submitFeedRecords: async function() {
      let errors = [];

      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.loading = true;
        this.ehm__unhandledErrorMessage = "";
        let payload = this.tableFeedData.filter(
          (feedRecord) => feedRecord.feed_dispensed > 0
        );
        if (payload.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$tc("Comn_must_greater_than", 1, {
                  field: this.$t("Day_feed_kg"),
                  threshold: 0
                })
              }
            ]
          };
        }
        payload = Object.values(
          payload.reduce(
            (
              acc,
              {
                pond_id,
                from_date,
                to_date,
                feed_dispensed,
                feed_id,
                pond_title
              }
            ) => {
              errors = [
                ...errors,
                ...this.validatePayload(feed_dispensed, pond_title)
              ];
              if (acc[pond_id]) {
                acc[pond_id].feeds.push({
                  feed_dispensed,
                  feed_id,
                  from_date,
                  to_date
                });
                acc[pond_id].feed_dispensed += feed_dispensed;
                return acc;
              } else {
                acc[pond_id] = { pond_id, from_date, to_date, feed_dispensed };
                acc[pond_id].feeds = [{ feed_dispensed, feed_id }];
                return acc;
              }
            },
            {}
          )
        );
        console.log("payload", payload);
        // const availDateRangeKeys = this.dhm__dateUtilsLib
        //   .eachDayOfInterval({
        //     start: this.dhm__dateUtilsLib.parse(
        //       this.dhm__dateUtilsLib.isoDayStartFormatString.replace(
        //         /<short-iso-date>/,
        //         this.date[0]
        //       ),
        //       this.dhm__dateUtilsLib.isoFormatString,
        //       new Date()
        //     ),
        //     end: this.dhm__dateUtilsLib.parse(
        //       this.dhm__dateUtilsLib.isoDayEndFormatString.replace(
        //         /<short-iso-date>/,
        //         this.date[1]
        //       ),
        //       this.dhm__dateUtilsLib.isoFormatString,
        //       new Date()
        //     )
        //   })
        //   .map((x) => {
        //     return this.dhm__dateUtilsLib.formatTZ(
        //       x,
        //       this.dhm__dateUtilsLib.isoFormatString
        //     );
        //   });
        // let finalPayload = [];
        // if (availDateRangeKeys.length) {
        //   finalPayload = availDateRangeKeys.reduce((acc, currentObj) => {
        //     const payloadClone = this.$lodash.cloneDeep(payload);
        //     payloadClone.map((item, index) => {
        //       item.date = currentObj;
        //     });
        //     acc.push(...payloadClone);
        //     return acc;
        //   }, []);
        // }
        if (errors.length > 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors
          };
        }
        await this.$store.dispatch("pond/saveManualFeedingRecords", payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("PM_feeding_data_succe_saved"),
          duration: 5000,
          type: "success"
        });
        if (payload.filter(ele => ele.feeds.length > 1).length > 0) {
          this.mixPanelEventGenerator({ eventName: "Feeding -Manual Feeding Table - add" })
        }
        this.mixPanelEventGenerator({ eventName: "Feeding -Manual Feeding Table - Save" })
        if (this.searchPond !== "") {
          this.tableFeedData = this.tableFeedData.map((val) => {
            val.feed_dispensed = 0;
            return { ...val };
          });
        } else {
          this.initComponent();
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
        this.showErrorAlerts = true;
      } finally {
        this.loading = false;
      }
    },
    ehm__error422Handler: function(err) {
      // let errorInnerHtml = ''
      const errors = [];
      if (err.response.data.errors != null) {
        err.response.data.errors.details.forEach((el, index) => {
          errors.push({ message: el.message });
        });
      } else {
        errors.push({ message: err.response.data.message });
      }
      this.ehm__errorFailToSave(errors);
    }
  }
};
</script>

<style lang="scss">
.culture-manual-feeding {
  @include responsiveProperty(--height-gutter, 162px, 170px, 177px);
  &.pondlogs-default-layout {
    padding: 0px;
  }
  .el-link {
    height: 22px;
    font-size: 1em;
    &--default {
      line-height: 1.2;
    }
  }
  .cell .er-button {
    @include small-text;
  }
  .er-data-tables .el-table .el-select {
    width: 250px;
  }
  .er-data-tables {
    td .cell {
      input[type="number"].el-input__inner {
        // @include responsiveProperty(width, 120px, 120%, 120px);
        width: 100px;
      }
      .el-input--mini .el-input__icon {
        line-height: 30px;
      }
    }
    .action-column-cell-row {
      padding-left: 15px;
    }
  }
  .btn-table-action {
    padding: 0px;
  }
  .switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .switch-label {
      margin-right: 8px;
      color: #3b5082;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
    .el-switch {
      margin: 0px;
    }
  }
}
</style>
